import React from 'react';
import './styles/Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="contacts">
      196084
    </div>
    <div className="phone-numbers">
      +79117484098 <br />
    </div>
    <div className="social-media">
      <a href="https://vk.com/vetconsult_1">ВКонтакте</a>
    </div>
  </footer>
);

export default Footer;
